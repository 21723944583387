<template>
  <div class="NotificationsPanel">
    <div class="NotificationsPanel__title">
      <h2>{{$t('notifications')}}</h2>
    </div>

    <preloader class="NotificationsPanel__Loader" v-if="loadingNotifications">
      <h2>{{$t('notifications_loading')}}</h2>
    </preloader>

    <div class="Notifications" v-if="!loadingNotifications">

      <ul class="Notifications__List" v-if="notifications">
        <li class="Notifications__List--Item"
          v-for="n in notifications"
          :key="n.id"
        >
          <notification-item
            :text="n.text.translated"
            :date="n.creationDate"
            :type="n.type.replace('EL_MOD', 'EL')"
            :seen="n.seen"
            @open-notification="
              openNewScreen( $event, n.activityId, n.communityId, n.commentId, n.commentPage )
            "
          ></notification-item>
        </li>
      </ul>

      <ul class="Notifications__List" v-if="!notifications">
        <li class="Notifications__List--Item">
          <h3 style="text-align:center">{{$t('notification_up_to_date')}}</h3>
        </li>
      </ul>

    </div>
  </div>
</template>

<script>
  import Preloader from '@/components/Preloader.vue';
  import NotificationItem from '@/components/NotificationItem.vue';
  import { mapGetters }   from 'vuex';
  import {
    MEDAL,MENTION , ANSWER, ACT_REMINDER, ACT_LAUNCHED, COM_LAUNCHED,EL_MOD_NEW_ANSWER_TO_COMMENT, WL_NEW_ANSWER_TO_COMMENT, WL_MOD_NEW_ANSWER_TO_COMMENT, WL_MOD_MENTIONED_IN_COMMENT
  } from '~/constants/constants.type'
  import {
    NOTIFICATIONS_CLEAR_ID
  } from '@/store/notifications/notifications.module';

  export default {
    name: 'NotificationsPage',

    components: {
      Preloader,
      NotificationItem,
    },

    computed: {
      ...mapGetters( [
        'loadingNotifications',
        'notifications',
        'isClient',
      ] )
    },

    data ( ) {
      return {
        MEDAL, ANSWER, ACT_REMINDER, ACT_LAUNCHED, COM_LAUNCHED
      }
    },

    methods: {
      navigateTo ( name, arg ) {
        this.$emit( 'close-panel' );

        const params = { origin: this.$route.params.origin }
        if ( arg )
          params.id = arg;
        this.$router.push( { name, params } );
      },

      async scrollToComment(commentId) {
        console.log(commentId)
      await this.$nextTick(); 
      const commentElement = document.querySelector(`.comment_${commentId}`);
      if (commentElement) {
        commentElement.scrollIntoView({ block: 'center', behavior: 'smooth' });
      commentElement.style.backgroundColor = 'rgba(255, 215, 0, 0.3)'; 
      commentElement.style.padding = '5px'; 
      commentElement.style.borderRadius = '5px'; 
          commentElement.style.transition = 'all 0.5s ease'; 
        setTimeout(() => {
          commentElement.style.backgroundColor = 'transparent'; 
          commentElement.style.padding = '0'; 
          commentElement.style.borderRadius = '0'; 
        }, 5000);
        }
    },

        openNewScreen(event, act, cmty, cmnt, cmntP) {
        console.log("openNewScreen  cmntP:", cmntP)
        console.log("openNewScreen  cmnt:", cmnt)
        console.log("openNewScreen  cmty:", cmty)
        console.log("openNewScreen  act:", act)
        console.log("openNewScreen  event:", event)
        
      const { type } = event;
      const currentRoute = this.$route;
      const isCurrentRoute = (name, params) => {
        return currentRoute.name === name &&
               (!params || Object.keys(params).every(key => currentRoute.params[key] === params[key]));
      };

      if (type === this.MEDAL) {
        if (!isCurrentRoute('profile')) {
          this.navigateTo('profile');
        }
      } else if (type === COM_LAUNCHED) {
        if (!isCurrentRoute('welcome')) {
          this.navigateTo('welcome');
        }
      } else if (type === ACT_LAUNCHED || type === ACT_REMINDER) {
        if (!isCurrentRoute('activity.info', { id: act })) {
          this.navigateTo('activity.info', act);
        }
      } else if (type === MENTION || type === ANSWER  || type === WL_MOD_MENTIONED_IN_COMMENT  || type === EL_MOD_NEW_ANSWER_TO_COMMENT || type === WL_NEW_ANSWER_TO_COMMENT || type === WL_MOD_NEW_ANSWER_TO_COMMENT) {
        if (isCurrentRoute('activity.info', { id: act })) {
          this.scrollToComment(cmnt);
        } else {
            this.$router.push({ name: 'activity.info', params: {notificationCommentId: cmnt, notificationCommentPage:cmntP, id:act }}).then(() => {
            this.scrollToComment(cmnt);
          });
        }
      }
    }
    },

    beforeDestroy ( ) {
      const ids = this.notifications ? this.notifications.map( d => d.id ) : [];
      this.$store.dispatch( NOTIFICATIONS_CLEAR_ID, { ids, isClient: this.isClient, hideSpinner: false } )
    }
  }
</script>

<style lang="scss">
  .container-fluid {
    height: 100% !important;
    min-height: unset !important;
  }
</style>

<style scoped lang="scss">
  .NotificationsPanel {
    display               : flex;
    flex-direction        : column;
    align-items           : center;
    min-height            : 100%;
    background            : white;

    &__title{
      display             : flex;
      justify-content     : space-between;
      font-weight         : bold;
      font-size           : 14px;
      width               : 100%;
      box-shadow          : 0px 2px 2px #f1f1f1;

      h2 {
        padding           : 10px;
        font-size         : 14px;
      }
    }

    &__Loader {
      flex-grow           : 1;
      width               : 100%;
      padding             : 3rem 1rem;
      text-align          : center;
      color               : var( --primary-color, #333 );
      animation           : blink 2s linear infinite;

      @keyframes blink {
        0%   { opacity: 1; }
        50%  { opacity: 0; }
        100% { opacity: 1; }
      }
    }

    .Notifications {
      width               : 100%;
      padding             : 3rem;

      @media screen and ( max-width: 768px ) {
        padding           : 0;
      }

      &__List {
        list-style-type   : none;

        &--Item {
          padding         : 1rem;
          border-bottom   : 1px solid lightgray;
        }
      }
    }
  }
</style>
